import type { SelectChangeEvent } from '@mui/material'
import type { FormikHelpers } from 'formik'
import type { Dayjs } from 'dayjs'
import React, { useCallback } from 'react'
import { useFormik } from 'formik'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import LoadingButton from '@mui/lab/LoadingButton'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import MultipleSelectSearch from '../search/MultipleSelectSearch'
import SelectBoard from '../form/SelectBoard'
import SelectPlayerList from '../form/SelectPlayerList'
import SelectIntelTextType from '../form/SelectIntelTextType'
import PermissionFeature from '../wrappers/PermissionFeature'
import {
    mapTagToSearchResult,
    mapSearchResultToTag,
    mapSearchResultToEntity,
    mapEntityToSearchResult,
} from '../../lib/utils/search'
import { getInitialFollowingModalFormValues } from '../../lib/hooks/useUserFollowing'
import ContentPermissionsSelect from './ContentPermissionsSelect'

export type FollowingModalFormType = DTO.UserFollowing & {
    minDate?: Dayjs | null
    maxDate?: Dayjs | null
    intelTextTypes?: Enum.IntelTextTypes[]
}
type FollowingModalFormProps = {
    submit: (variables: FollowingModalFormType, formikHelpers: FormikHelpers<FollowingModalFormType>) => void
    handleDiscard: () => void
    initialValues?: FollowingModalFormType
    title?: React.ReactNode
    subTitle?: React.ReactNode
    saveButtonText?: string
    discardButtonText?: string
    showSubjects?: boolean
    showAuthors?: boolean
    showContent?: boolean
    showBoards?: boolean
    showPlayerLists?: boolean | Enum.ReportLevel
    showKeywords?: boolean
    showDates?: boolean
    showIntelTextTypes?: boolean
    twoColumn?: boolean
}

const FollowingModalForm = ({
    submit,
    handleDiscard,
    initialValues = undefined,
    title = undefined,
    subTitle = undefined,
    saveButtonText = 'Save Changes',
    discardButtonText = 'Discard',
    showSubjects = true,
    showAuthors = true,
    showContent = true,
    showBoards = true,
    showPlayerLists = true,
    showKeywords = true,
    showDates = false,
    showIntelTextTypes = false,
    twoColumn = false,
}: FollowingModalFormProps): JSX.Element => {
    const formik = useFormik<FollowingModalFormType>({
        initialValues: initialValues || getInitialFollowingModalFormValues(),
        onSubmit: submit,
        enableReinitialize: true,
    })

    const onSubjectsChange = useCallback(
        (value: DTO.SearchResult[]) => {
            const subjects = value.map(mapSearchResultToTag)
            return formik.setFieldValue('subjects', subjects)
        },
        [formik]
    )

    const onAuthorsChange = useCallback(
        (value: DTO.SearchResult[]) => {
            const authors = value.map(mapSearchResultToEntity)
            return formik.setFieldValue('authors', authors)
        },
        [formik]
    )

    const onContentChange = (e: SelectChangeEvent<DTO.UserFollowingContent[]>) =>
        formik.setFieldValue('content', e.target.value)
    const onChipDelete = (contentType: Enum.ContentPermissionType) =>
        formik.setFieldValue(
            'content',
            formik.values.content.filter((obj) => obj.slug !== contentType)
        )

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container direction="column">
                {title && <DialogTitle sx={{ paddingX: 2, paddingY: 1 }}>{title}</DialogTitle>}
                <DialogContent sx={{ paddingX: 2, paddingBottom: 1 }}>
                    {subTitle && (
                        <Grid item mb={1}>
                            <Typography sx={{ color: 'text.secondary' }}>{subTitle}</Typography>
                        </Grid>
                    )}
                    {showDates && (
                        <Grid item container xs={12} columnSpacing={2}>
                            <Grid item xs={12} lg={twoColumn ? 6 : undefined} my={1}>
                                <DatePicker
                                    slotProps={{
                                        textField: {
                                            size: 'small',
                                            fullWidth: true,
                                        },
                                    }}
                                    label="Start Date"
                                    value={formik.values.minDate}
                                    onChange={(val: Dayjs | null) => formik.setFieldValue('minDate', val)}
                                    disableFuture
                                />
                            </Grid>
                            <Grid item xs={12} lg={twoColumn ? 6 : undefined} my={1}>
                                <DatePicker
                                    slotProps={{
                                        textField: {
                                            size: 'small',
                                            fullWidth: true,
                                        },
                                    }}
                                    label="End Date"
                                    value={formik.values.maxDate}
                                    onChange={(val: Dayjs | null) => formik.setFieldValue('maxDate', val)}
                                    disableFuture
                                />
                            </Grid>
                        </Grid>
                    )}
                    <Grid item container xs={12} columnSpacing={2}>
                        {showSubjects && (
                            <Grid item xs={12} lg={twoColumn ? 6 : undefined} my={1}>
                                <MultipleSelectSearch
                                    value={formik.values.subjects.map(mapTagToSearchResult)}
                                    onChange={onSubjectsChange}
                                    placeholder="Enter Subjects (e.g. Players, Teams, Agents)"
                                    searchType={['player', 'team', 'agent', 'staff']}
                                />
                            </Grid>
                        )}
                        {showAuthors && (
                            <Grid item xs={12} lg={twoColumn ? 6 : undefined} my={1}>
                                <MultipleSelectSearch
                                    value={formik.values.authors.map(mapEntityToSearchResult)}
                                    onChange={onAuthorsChange}
                                    placeholder="Authors"
                                    searchType="user"
                                />
                            </Grid>
                        )}
                        {showContent && (
                            <Grid item xs={12} lg={twoColumn ? 6 : undefined} my={1}>
                                <ContentPermissionsSelect
                                    value={formik.values.content}
                                    onChange={onContentChange}
                                    onDelete={onChipDelete}
                                />
                            </Grid>
                        )}
                        {showIntelTextTypes && (
                            <Grid item xs={12} lg={twoColumn ? 6 : undefined} my={1}>
                                <SelectIntelTextType
                                    name="intelTextTypes"
                                    value={formik.values.intelTextTypes}
                                    onChange={formik.handleChange}
                                    multiple
                                />
                            </Grid>
                        )}
                        {showBoards && (
                            <PermissionFeature type="custom-boards">
                                <Grid item xs={12} lg={twoColumn ? 6 : undefined} my={1}>
                                    <SelectBoard
                                        name="boards"
                                        onChange={formik.handleChange}
                                        value={formik.values.boards}
                                        level={['Pro', 'Amateur']}
                                        multiple
                                    />
                                </Grid>
                            </PermissionFeature>
                        )}
                        {!!showPlayerLists && (
                            <Grid item xs={12} lg={twoColumn ? 6 : undefined} my={1}>
                                <SelectPlayerList
                                    name="playerLists"
                                    label="Scouting Report Lists"
                                    onChange={formik.handleChange}
                                    value={formik.values.playerLists}
                                    level={showPlayerLists === true ? undefined : showPlayerLists}
                                />
                            </Grid>
                        )}
                        {showKeywords && (
                            <Grid item xs={12} lg={twoColumn ? 6 : undefined} my={1}>
                                <Autocomplete
                                    freeSolo
                                    autoSelect
                                    multiple
                                    options={[]}
                                    renderInput={(params) => {
                                        const inputProps = {
                                            ...params.InputProps,
                                            style: {
                                                paddingTop: 0,
                                                paddingBottom: 0,
                                                paddingRight: 3,
                                            },
                                        }
                                        return <TextField {...params} placeholder="Keywords" InputProps={inputProps} />
                                    }}
                                    onChange={(_, val) => formik.setFieldValue('keywords', val)}
                                    value={formik.values.keywords}
                                />
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid item container direction="row-reverse" spacing={2}>
                        <Grid item>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                loading={formik.isSubmitting}
                                disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
                            >
                                {saveButtonText}
                            </LoadingButton>
                        </Grid>
                        <Grid item>
                            <Button onClick={handleDiscard} disabled={formik.isSubmitting}>
                                {discardButtonText}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Grid>
        </form>
    )
}

export default FollowingModalForm
