import type { SelectProps } from '@mui/material/Select'
import React, { useMemo } from 'react'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { useBoardList } from '../../lib/hooks/useBoards'

type SelectBoardProps = {
    onChange: SelectProps['onChange']
    value: string | string[] | null
    leagues?: Enum.League[]
    level?: Enum.BoardLevels[]
    multiple?: boolean
    name?: string
    fullWidth?: boolean
    onClick?: SelectProps['onClick']
    rankedOnly?: boolean
    entityType?: Enum.BoardEntityType
    types?: Enum.BoardTypes[]
    designation?: Enum.BoardDesignation
    disabled?: boolean
    boardIds?: readonly string[]
}

const SelectBoard = ({
    onChange,
    value,
    leagues,
    level,
    multiple = false,
    name = 'boardId',
    fullWidth = undefined,
    onClick,
    rankedOnly,
    entityType = 'PLAYER',
    types,
    designation,
    disabled,
    boardIds,
}: SelectBoardProps): JSX.Element => {
    const { data: boardList } = useBoardList()
    const filteredBoardList = useMemo(() => {
        if (!boardList) return undefined

        const leagueSet = new Set(leagues)
        const levelSet = new Set(level)
        const boardSet = new Set(boardIds)

        if (
            leagueSet.has('NBA') ||
            leagueSet.has('G-League') ||
            leagueSet.has('Summer') ||
            leagueSet.has('International') ||
            leagueSet.has('FIBA')
        ) {
            levelSet.add('Pro')
        }
        if (leagueSet.has('College') || leagueSet.has('OTE') || leagueSet.has('Prep')) {
            levelSet.add('Amateur')
        }

        let bl = boardList.filter((d) => levelSet.has(d.level) && d.status === 'ACTIVE' && d.entityType === entityType)
        if (types?.length) bl = bl.filter((b) => types.includes(b.type))
        if (rankedOnly) return bl.filter((b) => b.isRanked)
        if (designation) return bl.filter((b) => b.designations?.includes(designation))
        // If boardIds is provided, filter the boardList to only include the boards with the provided boardIds
        if (boardIds?.length) return bl.filter((b) => boardSet.has(b.boardId))
        return bl
    }, [boardList, leagues, level, rankedOnly, entityType, types, designation, boardIds])

    return (
        <FormControl fullWidth size="small" disabled={disabled}>
            <InputLabel id="board-label">{multiple ? 'Board(s)' : 'Board'}</InputLabel>
            <Select
                labelId="board-label"
                label={multiple ? 'Board(s)' : 'Board'}
                name={name}
                onChange={onChange}
                value={value}
                multiple={multiple}
                fullWidth={fullWidth}
                onClick={onClick}
            >
                {filteredBoardList?.map((t) => (
                    <MenuItem key={t.boardId} value={t.boardId}>
                        {t.headline}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default SelectBoard
