import type { Except } from 'type-fest'
import type { ParsedUrlQueryInput } from 'querystring'
import type { FilterOptionValue } from './Filter'
import type { FollowingModalFormType } from './FollowingModalForm'
import Card from '@mui/material/Card'
import React, { useCallback, useMemo } from 'react'
import { usePathname, useSearchParams } from 'next/navigation'
import { useRouter } from 'next-nprogress-bar'
import { useSearchAsUserFollowing } from '../../lib/hooks'
import FollowingModalForm from './FollowingModalForm'
import { URLSearchParamsToObject, objectToURLSearchParams } from '@/lib/routing/searchParams'

type AdvancedSearchFormProps = {
    type: FilterOptionValue<true> | ''
    entityType: DTO.SearchResultType | undefined
}

const AdvancedSearchForm = ({ type, entityType: feedType }: AdvancedSearchFormProps): JSX.Element => {
    const router = useRouter()
    const pathname = usePathname()
    const searchParams = useSearchParams()
    const { data: form } = useSearchAsUserFollowing(URLSearchParamsToObject(searchParams), {
        keepPreviousData: true,
        cacheTime: Infinity,
    })

    const updatePostSearchQueryParams = useCallback(
        (params: DTO.AdvancedPostSearch | ParsedUrlQueryInput | null) => {
            const isolatedSearchParams: Pick<
                ParsedUrlQueryInput,
                Extract<
                    keyof DTO.AdvancedPostSearch,
                    DTO.AdvancedPostSearchType | 'intelTextTypes' | 'minDate' | 'maxDate'
                >
            > = {
                player: params?.player,
                team: params?.team,
                author: params?.author,
                staff: params?.staff,
                content: params?.content,
                agent: params?.agent,
                keyword: params?.keyword,
                playerList: params?.playerList,
                board: params?.board,
                intelTextTypes: params?.intelTextTypes,
                minDate: params?.minDate,
                maxDate: params?.maxDate,
            }

            // maintain essential query params (for routing) but replace all advanced search params
            let query: ParsedUrlQueryInput = {
                ...URLSearchParamsToObject(searchParams),
                ...isolatedSearchParams,
            }
            // remove any empty key/value pairs to clean up URL
            query = Object.entries(query).reduce<ParsedUrlQueryInput>((acc, [key, value]) => {
                if (value) acc[key] = value
                return acc
            }, {})

            return router.replace(`${pathname as string}?${objectToURLSearchParams(query)}`)
        },
        [router, pathname, searchParams]
    )

    const onAdvancedSearchSubmit = useCallback(
        (values: FollowingModalFormType) => {
            const advancedSearch: Except<DTO.AdvancedPostSearch, 'page' | 'perPage'> = {}

            if (values.authors.length) advancedSearch.author = values.authors.map((a) => a.entityId)
            if (values.content.length) advancedSearch.content = values.content.map((c) => c.slug)
            if (values.boards.length) advancedSearch.board = values.boards
            if (values.playerLists.length) advancedSearch.playerList = values.playerLists
            if (values.keywords.length) advancedSearch.keyword = values.keywords
            if (values.intelTextTypes?.length) advancedSearch.intelTextTypes = values.intelTextTypes
            if (values.minDate?.isValid()) advancedSearch.minDate = values.minDate.format('MM-DD-YYYY')
            if (values.maxDate?.isValid()) advancedSearch.maxDate = values.maxDate.format('MM-DD-YYYY')

            values.subjects.forEach(({ entityType, entityId }) => {
                if (!advancedSearch[entityType]) advancedSearch[entityType] = []
                // satisy TS by type checking the existence of the created array
                const entityTypeArr = advancedSearch[entityType]
                if (Array.isArray(entityTypeArr)) entityTypeArr.push(entityId)
            })

            updatePostSearchQueryParams(advancedSearch)
        },
        [updatePostSearchQueryParams]
    )

    const showPlayerLists = useMemo(() => {
        if (type === 'PRO_SCOUTING') return 'PRO'
        if (type === 'AM_SCOUTING') return 'AM'
        if (!type || type === 'MY_POSTS' || type === 'SCOUTING') return true
        return false
    }, [type])

    return (
        <Card variant="outlined">
            <FollowingModalForm
                initialValues={form || undefined}
                submit={onAdvancedSearchSubmit}
                handleDiscard={() => updatePostSearchQueryParams(null)}
                saveButtonText="Search"
                discardButtonText="Clear"
                showAuthors={type !== 'FOLLOWING' && type !== 'MY_POSTS' && feedType !== 'user'}
                showContent={type !== 'FOLLOWING' && (!type || type === 'MY_POSTS')}
                showSubjects={type !== 'FOLLOWING' && (!feedType || feedType === 'user')}
                showBoards={type !== 'FOLLOWING' && (!feedType || feedType !== 'player')}
                showPlayerLists={showPlayerLists}
                showKeywords={type !== 'FOLLOWING'}
                showIntelTextTypes={type === 'INTEL' || type === 'AM_INTEL' || type === 'PRO_INTEL'}
                showDates
                title="Advanced Filters"
                twoColumn
            />
        </Card>
    )
}

export default AdvancedSearchForm
